<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import router from "@/router/index.js";
import VueEasyLightbox from "vue-easy-lightbox";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
//import {reactive} from "vue";
//import { format } from "date-fns";

import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum,
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Information utilisateur",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, VueEasyLightbox },
  data() {
    return {
      title: "Agence détails",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Ajouter",
          active: true,
        },
      ],
      ordersData: [],
      dataVehiculesActif: [],
      dataVehiculesInactif: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      conducteur: [
        { key: "trajet", sortable: true, label: "Trajet" },
        { key: "date", sortable: true, label: "Départ" },
        { key: "prix", sortable: true, label: "Prix" },
        { key: "billingname", sortable: true, label: "Passagers" },
        { key: "statut", sortable: true, label: "Statut" },
      ],
      fields: [
        { key: "immatriculation", sortable: true, label: "Immatriculation" },
        { key: "model", sortable: true, label: "Model" },
        { key: "marque", sortable: true, label: "Marque" },
        { key: "compagnieAssurance", sortable: true, label: "Assurance" },
        {
          key: "prixPublieJournalierHorsVille",
          sortable: true,
          label: "Prix extramuros",
        },
        {
          key: "prixPublieJournalier",
          sortable: true,
          label: "Prix intramuros",
        },
        { key: "statut", sortable: true, label: "Statut" },
        { key: "action", label: "Actions" },
      ],
      fieldsAvis: [
        { key: "user", sortable: true, label: "Utilisateur" },
        { key: "date", sortable: true, label: "Data" },
        { key: "avis", sortable: true, label: "Avis" },
        { key: "vehicule", sortable: true, label: "Véhicule" },
        { key: "action", label: "Actions" },
      ],
      passager: [
        { key: "trajet", sortable: true, label: "Trajet" },
        { key: "date", sortable: true, label: "Départ" },
        { key: "prix", sortable: true, label: "Prix" },
        { key: "billingname", sortable: true, label: "Passagers" },
        { key: "statut", sortable: true, label: "Statut" },
      ],
      tokenUser: JSON.parse(localStorage.getItem("user")).tokenSalt,
      idUser: 0,
      fisrtname: "",
      lastname: "",
      genre: "",
      boite_postale: "",
      profession: "",
      birthday: "",
      adress: "",
      city: "",
      country: "",
      number_phone: "",
      email: "",
      password: "",
      showPassword: false,
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      index: 0,
      AutoMoto: {
        climatisation: "",
        anneeCirculation: "",
        categorie: "",
        couleur: "",
        immatriculation: "",
        model: "",
        motorisation: "",
        nomAuto: "",
        cartegrise: "",
        nombresiege: "",
      },
      fileProfil: null,
      filePermis: null,
      fileMoto: null,
      fileCni: null,
      user: {
        username: "",
        usertype: "",
        tel: "",
        email: "",
        levelRate: "",
        level: "",
        rating: "",
        active: "",
        badge: "",
      },
      vehiculeActif: [],
      vehiculeInActif: [],
      agence: {
        dateCreation: "",
        designation: "",
        email: "",
        ifu: "",
        imageCode: "",
        imageIFUCode: "",
        nbreVehicule: "",
        indicatifTel: "",
        nombreVote: "",
        responsable: "",
        tel: "",
        ville: "",
        actif: false,
        badge: false,
      },
      avis: [],
      preference: {
        acceptHandicape: "",
        discutIncar: "",
        eatIncar: "",
        musicIncar: "",
        smoke: "",
        stop: "",
      },
      verify: {
        email: false,
        permis: false,
        autoMoto: false,
        image: false,
        tel: false,
        cni: false,
      },
      activity: {
        publies: 0,
        annulees: 0,
        a_venir: 0,
        effectues: 0,
        gains: 0,
        reservations_annulees: 0,
        voyages_effectues: 0,
        reservations_effectuees: 0,
      },
      allImage: {
        avatar: "",
        permis: "",
        vehicule: "",
        cni: "",
      },
      showretire: false,
      showSucess: false,
      showEchec: false,
      userDesac: null,
      avislaisses: [],
      avisrecus: [],
      conducteurData: [],
      passagerData: [],
      img: "",
      vue: false,
      visible: false,
      showModalprofil: false,
      showModalcni: false,
      showModalpermis: false,
      showModalautoMoto: false,
      HistoriqueConducteur: false,
      HistoriquePassager: false,
      picture: ["PROFIL", "CNI", "PERMIS", "VOITURE"],
      imgs: "",
    };
  },
  validations: {
    firstname: { required },
    lastname: { required },
    genre: { required },
    number_phone: { required, numeric },
    email: { required, email },
    password: { required },

    tooltipform: {
      fname: { required },
      lname: { required },
      username: { required },
      city: { required },
      state: { required },
    },
    range: {
      minlen: { required, minLength: minLength(6) },
      maxlength: { required, maxLength: maxLength(6) },
      between: { required, minLength: minLength(5), maxLength: maxLength(10) },
      minval: { required, minValue: minValue(6) },
      maxval: { required, maxValue: maxValue(6) },
      rangeval: { required, minValue: minValue(6), maxValue: maxValue(100) },
      expr: { required },
    },
    typeform: {
      name: { required },
      password: { required, minLength: minLength(6) },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
      email: { required, email },
      url: { required, url },
      digit: { required, numeric },
      number: { required, numeric },
      alphanum: { required, alphaNum },
      textarea: { required },
    },
  },

  async mounted() {
    this.init();
  },
  methods: {
    async init() {
      //console.log("tokenUser:", this.tokenUser);
      this.idUser = this.$route.params.row;
     
      //Chargement des données
      const data = await apiRequest(
        "GET",
        "admin/rmo/agence-details?id=" + this.idUser,
        false
      );
     
      if (data && data.data) {
        //console.log("data.data:", data.data);
        this.agence.responsable = data.data.responsable;
        this.agence.dateCreation = new Date(
          data.data.dateCreation
        ).toLocaleString("fr-FR");
        this.agence.designation = data.data.designation;
        this.agence.email = data.data.email;
        this.agence.tel = data.data.tel;
        this.agence.ifu = data.data.ifu;
        this.agence.ville = data.data.ville;
        this.agence.indicatifTel = data.data.indicatifTel;
        this.agence.actif = data.data.actif;
        data.data.badge
          ? (this.agence.badge = data.data.badge)
          : (this.agence.badge = false);

        this.avis = data.data.avis;
        this.dataVehiculesActif = data.data.vehiculeActif;
        this.dataVehiculesInactif = data.data.vehiculeInActif;
        data.data.imageCode
          ? (this.allImage.avatar = data.data.imageCode)
          : (this.allImage.avatar = require("@/assets/images/users/attente.png"));
      }

      //console.log("data.activity:", dataActivity.data)
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },

    verification: function() {
     
    },

    ValidationStatus: function(validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },

    Submit: function() {
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      alert("data submit");
    },
    showImg(index) {
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    toogleShowPassword() {
      var show = document.getElementById("password");
      if (this.showPassword == false) {
        this.showPassword = true;
        show.type = "text";
      } else {
        this.showPassword = false;
        show.type = "password";
      }
    },

    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
    },

    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    showSingle() {
      this.imgs = require("@/assets/images/small/img-2.jpg");
      this.show();
    },
    showImage(img) {
      //console.log(img.srcElement.src)
      this.imgs = img.srcElement.src;
     
      this.show();
    },
    show() {
      this.visible = true;
    },

    handleFileProfil() {
      this.fileProfil = this.$refs.fileProfil.files[0];
    },
    handleFilePermis() {
      this.filePermis = this.$refs.filePermis.files[0];
    },
    handleFileMoto() {
      this.fileMoto = this.$refs.fileMoto.files[0];
    },
    handleFileCni() {
      this.fileCni = this.$refs.fileCni.files[0];
    },

    async verifyPictureRefuse() {
      const verify = await apiRequest(
        "POST",
        "dash/response-profile",
        { idUser: this.idUser, reponse: 0 },
        false
      );

     
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },
    goToDetail(row) {
     
      router.push(`/vehicule/historique/${row.id}`);
      //router.push('/user/user_info/'+row.id)
    },
    async verifyPictureAccepte() {
      const verify = await apiRequest(
        "POST",
        "dash/response-profile",
        { idUser: this.idUser, reponse: 1 },
        false
      );

     
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },

    async verifyCniRefuse() {
      const verify = await apiRequest(
        "POST",
        "dash/response-cni",
        { idUser: this.idUser, reponse: 0 },
        false
      );

     
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },

    async verifyCniAccepte() {
      const verify = await apiRequest(
        "POST",
        "dash/response-cni",
        { idUser: this.idUser, reponse: 1 },
        false
      );

     
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },

    async verifyPermisRefuse() {
      const verify = await apiRequest(
        "POST",
        "dash/response-permis",
        { idUser: this.idUser, reponse: 0 },
        false
      );

     
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },

    async verifyPermisAccepte() {
      const verify = await apiRequest(
        "POST",
        "dash/response-permis",
        { idUser: this.idUser, reponse: 1 },
        false
      );

     
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },

    async verifyVehiculeRefuse() {
      const verify = await apiRequest(
        "POST",
        "dash/response-vehicule",
        { idUser: this.idUser, reponse: 0 },
        false
      );

     
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },

    async verifyVehiculeAccepte() {
      const verify = await apiRequest(
        "POST",
        "dash/response-vehicule",
        { idUser: this.idUser, reponse: 1 },
        false
      );

     
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },

    async verifyAvisRefuse(idAvis) {
      const verify = await apiRequest(
        "POST",
        "admin/verif-avis",
        { idAvis: idAvis, valide: 0 },
        false
      );

     
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },
    async verifyAvisAccepte(idAvis) {
      const verify = await apiRequest(
        "POST",
        "admin/verif-avis",
        { idAvis: idAvis, valide: 1 },
        false
      );

     
      if (verify && verify.status == 200) {
        window.location.reload();
      }
    },
    async desactiverUser() {
      const desacUser = await apiRequest(
        "POST",
        "admin/suspendre-user",
        { idUser: this.idUser },
        false
      );

      if (desacUser && desacUser.data) {
        this.init();
        //console.log("Test:",this.userDesac)
      }
    },
    async ActiverUser() {
      const activUser = await apiRequest(
        "POST",
        "admin/activer-user",
        { idUser: this.idUser },
        false
      );

      if (activUser && activUser.data) {
        this.init();
      }
    },
    async attribuerBadge() {
      const badge = await apiRequest(
        "POST",
        "admin/rmo/agence/badge",
        {
          id: this.idUser,
          badge: true,
        },
        false
      );
      if (badge && badge.data) {
        this.init();
        this.showSucess = true;
      } else {
        this.showEchec = true;
      }
    },
    async retirerBadge() {
      const retirer = await apiRequest(
        "POST",
        "admin/rmo/agence/badge",
        {
          id: this.idUser,
          badge: false,
        },
        false
      );
      if (retirer && retirer.data) {
        this.init();
        this.showretire = true;
      } else {
        this.showEchec = true;
      }
    },
    async addCNI() {
      this.showModalcni = false;
      if (this.fileCni) {
        let formData = new FormData();
        formData.append("idUser", this.idUser);
        formData.append("cni", this.fileCni);

        const returnFilecni = await apiRequest(
          "POST",
          "admin/user-cni-pic",
          formData,
          true
        );

        if (returnFilecni && returnFilecni.data) {
          this.init();
         
         
        }
      }
    },
    async addProfil() {
      this.showModalprofil = false;
      if (this.fileProfil) {
        let formData = new FormData();
        formData.append("id", this.idUser);
        formData.append("avatar", this.fileProfil);

        const returnFileProfil = await apiRequest(
          "POST",
          "admin/user-pic",
          formData,
          true
        );

        if (returnFileProfil && returnFileProfil.data) {
         
          this.init();
        }
      }
    },
    async addPermis() {
      this.showModalpermis = false;
      if (this.filePermis) {
        let formData = new FormData();
        formData.append("idUser", this.idUser);
        formData.append("file", this.filePermis);

        const returnFilePermis = await apiRequest(
          "POST",
          "admin/user-permis-pic",
          formData,
          true
        );

        if (returnFilePermis && returnFilePermis.data) {
         
          this.init();
        }
      }
    },
    async addAutoMoto() {
      this.showModalautoMoto = false;
      if (this.fileMoto) {
        let formData = new FormData();
        formData.append("idUser", this.idUser);
        formData.append("file", this.fileMoto);

        const returnFileAutoMoto = await apiRequest(
          "POST",
          "admin/user-automoto-pic",
          formData,
          true
        );

        if (returnFileAutoMoto && returnFileAutoMoto.data) {
         
          this.init();
        }
      }
    },
  },

  CloseModalProfil() {
    this.showModalprofil = false;
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card ml-3 mr-3 ">
          <div class="card-body p-3 ">
            <div class="row">
              <div class="col-md-2">
              <b-overlay
                id="overlay-background"
                :show="allImage.avatar == ''"
                :variant="dark"
                :opacity="1"
                :blur="blur"
                rounded="md"
              >
                <a class="image-popup-vertical-fit ml-5" @click="showImage">
                  <img
                    style="cursor: pointer"
                    class=" avatar-xl"
                    :src="`${this.allImage.avatar}`"
                    data-holder-rendered="true"
                  />
                  <!-- <img class="img-time-matters" :src="require(`../assets/time-comparison-${locale}.png`)"> -->
                </a>
              </b-overlay>
              <vue-easy-lightbox
                :visible="vue"
                :imgs="img"
                @hide="handleHide"
              ></vue-easy-lightbox>
              </div>
              <div class="col-md-5">
                <div class="mt-2">
                  <div class="theTitle">Nom de l'agence</div>
                  <div>{{ this.agence.designation }}</div>
                </div>
                <div class="mt-2">
                  <div class="theTitle">Contact de l'agence</div>
                  <div>{{ this.agence.tel }}</div>
                </div>
                <div class="mt-2">
                  <div class="theTitle">Adresse mail de l'agence</div>
                  <div>{{ this.agence.email }}</div>
                </div>
                <div class="mt-2">
                  <div class="theTitle">Nom et prénom du dirigeant</div>
                  <div>{{ this.agence.responsable }}</div>
                </div>
              </div>
       
              <div class=" col-md-5 ">
                <div class="btnContainer">
                  <div v-if="this.agence.actif == true">
                    <button
                      type="submit"
                      @click="desactiverUser"
                      class="btn btn-warning mb-2 mr-2"
                    >
                      <i class="mdi mdi-account-remove mr-2"></i>Désactiver
                      cette agence
                    </button>
                  </div>
                  <div v-else>
                    <button
                      type="submit"
                      @click="ActiverUser"
                      class="btn btn-info mb-2 mr-2"
                    >
                      <i class="mdi mdi-account-remove mr-2"></i>Activer cete
                      agence
                    </button>
                  </div>
                  <button type="submit" class="btn btn-danger mb-2 mr-2">
                    <i class="ri-close-line align-middle mr-2"></i>Supprimer
                    cette agence
                  </button>
                  <div v-if="this.agence.badge == true">
                    <button
                      type="submit"
                      @click="retirerBadge"
                      class="btn btn-info mb-2 mr-2"
                    >
                      <i class="mdi mdi-certificate align-middle mr-2"></i
                      >Retirer le badge
                    </button>
                  </div>
                  <div v-else>
                    <button
                      type="submit"
                      @click="attribuerBadge"
                      class="btn btn-success mb-2 mr-2"
                    >
                      <i class="mdi mdi-certificate align-middle mr-2"></i
                      >Attribuer un badge
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="card">
        <div class="card-body mt-3">
          <b-tabs pills justified content-class="p-3 text-muted">
            <b-tab active class="border-0">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="fas fa-home"></i>
                </span>
                <span class="d-none d-sm-inline-block"
                  ><i class="mdi mdi-clipboard-account mr-2"></i>LES AVIS</span
                >
              </template>
              <h4 class="card-title mt-4">
                Nombre Total des avis: {{ avis.length }}
              </h4>
              <div class="row mt-4">
                <div class="col-sm-12 col-md-3">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      Afficher&nbsp;
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                      ></b-form-select
                      >&nbsp;entrées
                    </label>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <!-- <label class="d-inline-flex align-items-center">
                        Choisir
                    <multiselect
                    v-model="value1"
                    :options="options"
                    :multiple="true"
                    :searchable="false" class="col-md-12"
                    ></multiselect>
                    </label> -->
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-3">
                  <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-right"
                  >
                    <label class="d-inline-flex align-items-center">
                      Rechercher:
                      <b-form-input
                        v-model="filter"
                        type="search"
                        class="form-control form-control-sm ml-2"
                      ></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>

              <div class="table-responsive">
                <b-table
                  style="cursor: pointer"
                  class="table-centered"
                  :items="avis"
                  :fields="fieldsAvis"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fieldsAvis="filterOn"
                  @filtered="onFiltered"
                  @row-clicked="goToDetail"
                >
                  <template v-slot:cell(statut)="row">
                    <div
                      class="py-2 d-flex justify-content-center badge font-size-12"
                      :class="{
                        'badge-soft-danger': `${row.value}` === 'PANNE',
                        'badge-soft-success': `${row.value}` === 'DISPO RMO',
                        'badge-soft-success': `${row.value}` === 'DISPO AGENCE',
                        'badge-soft-warning': `${row.value}` === 'LOUER',
                      }"
                    >
                      {{ row.value }}
                    </div>
                  </template>
                  <template v-slot:cell(action)="row">
                    <a
                      href="javascript:void(0);"
                      class="text-success"
                      v-b-tooltip.hover
                      @click="desactiverUser(row.item)"
                      title="Activer"
                    >
                      <i class="mdi mdi-check font-size-18"></i>
                    </a>
                  </template>
                </b-table>
              </div>

              <div class="row">
                <div class="col">
                  <div
                    class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-right
                      "
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </b-tab>

            <b-tab title-link-class="p-3">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none ">
                  <i class="far fa-user"></i>
                </span>
                <span class="d-none d-sm-inline-block"
                  ><i class="mdi mdi-format-list-bulleted mr-2"></i
                  >Véhicules</span
                >
              </template>

              <b-tabs pills justified content-class="p-3 text-muted">
                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none ">
                      <i class="far fa-user"></i>
                    </span>
                    <span class="d-none d-sm-inline-block "
                      ><i class="mdi mdi-format-list-bulleted mr-2"></i>
                      Actifs</span
                    >
                  </template>

                  <h4 class="card-title mt-4">
                    Nombre Total de véhicule: {{ dataVehiculesActif.length }}
                  </h4>
                  <div class="row mt-4">
                    <div class="col-sm-12 col-md-3">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Afficher&nbsp;
                          <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                          ></b-form-select
                          >&nbsp;entrées
                        </label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <!-- <label class="d-inline-flex align-items-center">
                        Choisir
                    <multiselect
                    v-model="value1"
                    :options="options"
                    :multiple="true"
                    :searchable="false" class="col-md-12"
                    ></multiselect>
                    </label> -->
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-3">
                      <div
                        id="tickets-table_filter"
                        class="dataTables_filter text-md-right"
                      >
                        <label class="d-inline-flex align-items-center">
                          Rechercher:
                          <b-form-input
                            v-model="filter"
                            type="search"
                            class="form-control form-control-sm ml-2"
                          ></b-form-input>
                        </label>
                      </div>
                    </div>
                    <!-- End search -->
                  </div>

                  <div class="table-responsive">
                    <b-table
                      style="cursor: pointer"
                      class="table-centered"
                      :items="dataVehiculesActif"
                      :fields="fields"
                      responsive="sm"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                      @row-clicked="goToDetail"
                    >
                      <template v-slot:cell(statut)="row">
                        <div
                          class="py-2 d-flex justify-content-center badge font-size-12"
                          :class="{
                            'badge-soft-danger': `${row.value}` === 'PANNE',
                            'badge-soft-success':
                              `${row.value}` === 'DISPO RMO',
                            'badge-soft-success':
                              `${row.value}` === 'DISPO AGENCE',
                            'badge-soft-warning': `${row.value}` === 'LOUER',
                          }"
                        >
                          {{ row.value }}
                        </div>
                      </template>
                      <template v-slot:cell(action)="row">
                        <a
                          href="javascript:void(0);"
                          class="text-danger"
                          v-b-tooltip.hover
                          @click="desactiverUser(row.item)"
                          title="Désactiver"
                        >
                          <i class="mdi mdi-close font-size-18"></i>
                        </a>
                      </template>
                    </b-table>
                  </div>

                  <div class="row">
                    <div class="col">
                      <div
                        class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-right
                      "
                      >
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage"
                          ></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </b-tab>

                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <span class="d-inline-block d-sm-none">
                      <i class="far fa-user"></i>
                    </span>
                    <span class="d-none d-sm-inline-block"
                      ><i class="mdi mdi-format-list-bulleted mr-2"></i
                      >Désactivés</span
                    >
                  </template>

                  <h4 class="card-title mt-4">
                    Nombre Total de véhicule: {{ dataVehiculesInactif.length }}
                  </h4>
                  <div class="row mt-4">
                    <div class="col-sm-12 col-md-3">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Afficher&nbsp;
                          <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                          ></b-form-select
                          >&nbsp;entrées
                        </label>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <!-- <label class="d-inline-flex align-items-center">
                        Choisir
                    <multiselect
                    v-model="value1"
                    :options="options"
                    :multiple="true"
                    :searchable="false" class="col-md-12"
                    ></multiselect>
                    </label> -->
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-3">
                      <div
                        id="tickets-table_filter"
                        class="dataTables_filter text-md-right"
                      >
                        <label class="d-inline-flex align-items-center">
                          Rechercher:
                          <b-form-input
                            v-model="filter"
                            type="search"
                            class="form-control form-control-sm ml-2"
                          ></b-form-input>
                        </label>
                      </div>
                    </div>
                    <!-- End search -->
                  </div>

                  <div class="table-responsive">
                    <b-table
                      style="cursor: pointer"
                      class="table-centered"
                      :items="dataVehiculesInactif"
                      :fields="fields"
                      responsive="sm"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                      @row-clicked="goToDetail"
                    >
                      <template v-slot:cell(statut)="row">
                        <div
                          class="py-2 d-flex justify-content-center badge font-size-12"
                          :class="{
                            'badge-soft-danger': `${row.value}` === 'PANNE',
                            'badge-soft-success':
                              `${row.value}` === 'DISPO RMO',
                            'badge-soft-success':
                              `${row.value}` === 'DISPO AGENCE',
                            'badge-soft-warning': `${row.value}` === 'LOUER',
                          }"
                        >
                          {{ row.value }}
                        </div>
                      </template>
                      <template v-slot:cell(action)="row">
                        <a
                          href="javascript:void(0);"
                          class="text-success"
                          v-b-tooltip.hover
                          @click="desactiverUser(row.item)"
                          title="Activer"
                        >
                          <i class="mdi mdi-check font-size-18"></i>
                        </a>
                      </template>
                    </b-table>
                  </div>

                  <div class="row">
                    <div class="col">
                      <div
                        class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-right
                      "
                      >
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage"
                          ></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-backdrop"
      centered
      v-model="showModalprofil"
      title="Téléverser ce profil ?"
      title-class="font-18"
      hide-footer
    >
      <form action="">
        <input
          type="file"
          id="fileProfil"
          ref="fileProfil"
          v-on:change="handleFileProfil"
          class="form-control"
          placeholder="Photo de permis"
        />
        <div class="row float-right mt-3">
          <b-button variant="danger" @click="showModalprofil = false"
            >Non</b-button
          >
          <b-button variant="success" class="ml-3 mr-4" @click="addProfil"
            >Oui</b-button
          >
        </div>
      </form>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showModalcni"
      title="Téléverser cette CNI ?"
      title-class="font-18"
      hide-footer
    >
      <form action="">
        <input
          type="file"
          id="fileCni"
          ref="fileCni"
          v-on:change="handleFileCni"
          class="form-control"
          placeholder="Photo de permis"
        />
        <div class="row float-right mt-3">
          <b-button variant="danger" @click="showModalcni = false"
            >Non</b-button
          >
          <b-button variant="success" class="ml-3 mr-4" @click="addCNI"
            >Oui</b-button
          >
        </div>
      </form>
    </b-modal>
    <b-modal
      id="modal-backdrop"
      centered
      v-model="showModalpermis"
      title="Téléverser ce permis ?"
      title-class="font-18"
      hide-footer
    >
      <form action="">
        <input
          type="file"
          id="filePermis"
          ref="filePermis"
          v-on:change="handleFilePermis"
          class="form-control"
          placeholder="Photo de permis"
        />
        <div class="row float-right mt-3">
          <b-button variant="danger" @click="showModalpermis = false"
            >Non</b-button
          >
          <b-button variant="success" class="ml-3 mr-4" @click="addPermis"
            >Oui</b-button
          >
        </div>
      </form>
    </b-modal>
    <b-modal
      id="modal-backdrop"
      centered
      v-model="showModalautoMoto"
      title="Téléverser ce Auto/Moto ?"
      title-class="font-18"
      hide-footer
    >
      <form action="">
        <input
          type="file"
          id="fileMoto"
          ref="fileMoto"
          v-on:change="handleFileMoto"
          class="form-control"
          placeholder="Photo de permis"
        />
        <div class="row float-right mt-3">
          <b-button variant="danger" @click="showModalautoMoto = false"
            >Non</b-button
          >
          <b-button variant="success" class="ml-3 mr-4" @click="addAutoMoto"
            >Oui</b-button
          >
        </div>
      </form>
    </b-modal>

    <b-modal
      size="xl"
      hide-footer
      centered
      v-model="HistoriqueConducteur"
      title="Historique Conducteur"
      title-class="font-18"
    >
      <b-tabs nav-class="nav-tabs-custom">
        <b-tab title-link-class="p-3">
          <template v-slot:title>
            <a class="font-weight-bold active">Historique des trajets</a>
          </template>
          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Afficher&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entrées
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Rechercher:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <div class="table-responsive">
            <b-table
              class="table-centered"
              :items="conducteurData"
              :fields="conducteur"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:cell(paymentstatus)="row">
                <div
                  class="badge font-size-12"
                  :class="{
                    'badge-soft-danger': `${row.value}` === 'Chargeback',
                    'badge-soft-success': `${row.value}` === 'Paid',
                    'badge-soft-warning': `${row.value}` === 'Unpaid',
                  }"
                >
                  {{ row.value }}
                </div>
              </template>
              <template v-slot:cell(invoice)>
                <button class="btn btn-light btn-rounded">
                  Invoice
                  <i class="mdi mdi-download ml-2"></i>
                </button>
              </template>
              <template v-slot:cell(action)>
                <a
                  href="javascript:void(0);"
                  class="mr-3 text-primary"
                  v-b-tooltip.hover
                  title="Edit"
                >
                  <i class="mdi mdi-pencil font-size-18"></i>
                </a>
                <a
                  href="javascript:void(0);"
                  class="text-danger"
                  v-b-tooltip.hover
                  title="Delete"
                >
                  <i class="mdi mdi-trash-can font-size-18"></i>
                </a>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :prev-text="'Précédent'"
                    :next-text="'suivant'"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>

    <b-modal
      size="xl"
      hide-footer
      centered
      v-model="HistoriquePassager"
      title="Historique Passager"
      title-class="font-18"
    >
      <b-tabs nav-class="nav-tabs-custom">
        <b-tab title-link-class="p-3">
          <template v-slot:title>
            <a class="font-weight-bold active">Historique des trajets</a>
          </template>
          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Afficher&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entrées
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Rechercher:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <div class="table-responsive">
            <b-table
              class="table-centered"
              :items="passagerData"
              :fields="passager"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:cell(paymentstatus)="row">
                <div
                  class="badge font-size-12"
                  :class="{
                    'badge-soft-danger': `${row.value}` === 'Chargeback',
                    'badge-soft-success': `${row.value}` === 'Paid',
                    'badge-soft-warning': `${row.value}` === 'Unpaid',
                  }"
                >
                  {{ row.value }}
                </div>
              </template>
              <template v-slot:cell(invoice)>
                <button class="btn btn-light btn-rounded">
                  Invoice
                  <i class="mdi mdi-download ml-2"></i>
                </button>
              </template>
              <template v-slot:cell(action)>
                <a
                  href="javascript:void(0);"
                  class="mr-3 text-primary"
                  v-b-tooltip.hover
                  title="Edit"
                >
                  <i class="mdi mdi-pencil font-size-18"></i>
                </a>
                <a
                  href="javascript:void(0);"
                  class="text-danger"
                  v-b-tooltip.hover
                  title="Delete"
                >
                  <i class="mdi mdi-trash-can font-size-18"></i>
                </a>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :prev-text="'Précédent'"
                    :next-text="'suivant'"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showSucess"
      title="Badge attribué avec succès !!!"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showSucess = false">OK</b-button>
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showretire"
      title="Badge retiré avec succès !!!"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showSucess = false">OK</b-button>
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showEchec"
      title="Badge désactivé"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showEchec = false"
          >Reéssayez</b-button
        >
      </div>
    </b-modal>
  </Layout>
</template>

<style>
label {
  font-size: 20px;
}
.divider {
  width: 100%;
  border: solid 2px blue;
}
.blueG {
  border: solid 1px #8608b8ad;
}
.YelG {
  border: solid 1px #ff6545;
}
.text-blueG {
  color: #8608b8ad;
}

.borderPointer {
  border-left: 2px solid rgb(209, 205, 205);

  padding-top: 0px !important;
}

.maDivBar{
  border-left: 2px solid rgb(209, 205, 205);

  padding-top: 0px !important;
  height: inherit;

}


.theTitle {
  font-size: 16px;
  display: inline-block;
  font-weight: 700;
  text-align: left;
  color: #222;
}

.btnContainer {
 display: flex;


 justify-content: flex-start;
}
</style>
